import React from "react";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class SectionPortfolio extends React.Component {
 
  render() {
    return (
      <section id="section6">
       
        <div className="container">
          <div className="portfolio-wrapper">
            <div id="portfolio-filters" className="portfolio-filters">
              <button
                className="button solid-button white-purple small"
                data-filter="*"
              >
                הכל
              </button>
              <button
                className="button solid-button white-purple small"
                data-filter=".branding"
              >
                מיתוג
              </button>
              <button
                className="button solid-button white-purple small"
                data-filter=".print"
              >
                פרינט
              </button>
              <button
                className="button solid-button white-purple small"
                data-filter=".websites"
              >
                אתרי אינטרנט
              </button>
              <Element name="portfolio" className="portfolio" ></Element>
            </div>
            <div id="portfolio" className="portfolio">
              <div className="item websites">
                <img
                  src="images/mockups/277X277/izkor.png"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://www.izkor.gov.il/"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">izkor.gov.il</span>
                    <span className="category">פיתוח Front-end</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/lightico.png"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  target="_blank" rel="noopener noreferrer"
                  href="https://lightico.com"
                  className="overlay"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">lightico.com</span>
                    <span className="category">אתר לחברת סטארטאפ</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/meydale.png"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  target="_blank" rel="noopener noreferrer"
                  href="https://maydale.org.il/"
                  className="overlay"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">maydale.org.il</span>
                    <span className="category">פורטל</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/bonner.png"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://bonner.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">bonner.co.il</span>
                    <span className="category">אתר תדדמית ליועץ עסקי</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/news.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">blog</span>
                    <span className="category">
                      אתר חדשות מסונכרן עם פייסבוק
                    </span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/faygin.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  target="_blank" rel="noopener noreferrer"
                  href="https://faygin.com"
                  className="overlay"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">faygin.com</span>
                    <span className="category">חנות אינטרנטית</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/project-vertical-01.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">ThomSoon</span>
                    <span className="category">כרטיסי ביקור</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item print">
                <img
                  src="images/mockups/277X277/branding/all-seminars-2019.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">דרך הידיים</span>
                    <span className="category">פלייר להדפסה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item print">
                <img
                  src="images/mockups/277X277/branding/hamazganish.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://hamazganish.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">המזגנאיש</span>
                    <span className="category">חוברת להדפסה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/ella-shahaf.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">אלה שחף - נתיב חדש</span>
                    <span className="category">מיתוג</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item print">
                <img
                  src="images/mockups/277X277/branding/flayer.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">Security National Bank</span>
                    <span className="category">פלייר להדפסה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/project-square-01.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">חברת עורכי דין</span>
                    <span className="category">מיתוג</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/project-horizontal-01.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">Simply Nota</span>
                    <span className="category">מיתוג</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/adamolam.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="http://adam-olam.co.il/"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">לוגו למכללת "אדם ועולמו"</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item branding">
                <img
                  src="images/mockups/277X277/branding/therapy.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">תרפיה</span>
                    <span className="category">מיתוג</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item print branding">
                <img
                  src="images/mockups/277X277/branding/project-vertical-02.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">הדרכות לימוד</span>
                    <span className="category">מיתוג</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/cnof.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://cnof.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">cnof.co.il</span>
                    <span className="category">Print</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/ellashahaf.png"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://ellashahaf.co.il/"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">ellashahaf.co.il</span>
                    <span className="category">אתר תדמית</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/bright-biz.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://brightbiz.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">brightbiz.co.il</span>
                    <span className="category">אתר תדמית ליועץ עסקי</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/dereh-hayadaim-lp.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  target="_blank" rel="noopener noreferrer"
                  href="https://derech-hayadaim.co.il/%D7%A1%D7%93%D7%A0%D7%90%D7%95%D7%AA-%D7%92%D7%99%D7%A0%D7%95%D7%9F-%D7%95%D7%A0%D7%92%D7%A8%D7%95%D7%AA/"
                  className="overlay"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">derech-hayadaim.co.il</span>
                    <span className="category">דף נחיתה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/nativ-lp.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://nativ-creative.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">nativ-creative.co.il</span>
                    <span className="category">דף נחיתה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/shahar.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="http://www.shachar-shinar.co.il/"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">shachar-shinar.co.il</span>
                    <span className="category">רדיזיין של אתר תדמית</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/wending-event.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="http://wending-event.com/"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">wending-event.com</span>
                    <span className="category">אתר לאירוע חתונה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/bamidbar.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://bamidbar.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">bamidbar.co.il</span>
                    <span className="category">אתר תדמית למעצבת פנים</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/colorfull.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://www.colorfull.ru/"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">colorfull.ru</span>
                    <span className="category">אתר תדמית</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/dereh-hayadaim.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://derech-hayadaim.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">derech-hayadaim.co.il</span>
                    <span className="category">אתר תדמית</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/event.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://seminars-event.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">seminars-event.co.il</span>
                    <span className="category">אתר אירועים</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/hamazganish.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://hamazganish.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">hamazganish.co.il</span>
                    <span className="category">רדיזיין של האתר</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/hosting.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay" target="_blank" rel="noopener noreferrer">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">Hosting</span>
                    <span className="category">אתר לחברת אחסון</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/lp-yoga.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a href="#" className="overlay" target="_blank" rel="noopener noreferrer">
                  <div className="background" />
                  <div className="meta">
                    <span className="title">Yoga</span>
                    <span className="category">אתר תדמית לסטודיו ליוגה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/nagarut.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="http://wood-fantasy.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">wood-fantasy.co.il</span>
                    <span className="category">אתר תדמית לנגייה</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/ozkaltman.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://ozkaltman.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">ozkaltman.co.il</span>
                    <span className="category">Websites</span>
                  </div>
                  
                </a>
                
              </div>
              
              <div className="item websites">
                <img
                  src="images/mockups/277X277/yuliaheal.jpg"
                  alt="alt text"
                  className="img-responsive"
                />
                <a
                  href="https://yuliaheal.co.il"
                  className="overlay"
                  target="_blank" rel="noopener noreferrer"
                >
                  <div className="background" />
                  <div className="meta">
                    <span className="title">yuliaheal.co.il</span>
                    <span className="category">אתר תדמית</span>
                  </div>
                  
                </a>
                
              </div>
              
            </div>
            
             
          </div>
          
        </div>
        
      </section>
    );
  }
}

export default SectionPortfolio;
