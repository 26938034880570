import React from 'react';
import spinner from '../images/ajax-loader.gif'

function ShowDetail() {
  return (
    <div className="loader">
      
     <h1> WoWeb Studio<img src={spinner} width="200px"/></h1>
    </div>
  );
}

export default ShowDetail;
