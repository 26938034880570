import React from "react";
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NotFoundPage from './NotFoundPage';
import ThankyouPage from './pages/ThankyouPage';

class App extends React.Component {
    
    render(){
        return(
          
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route path="/thankyou" component={ThankyouPage} />
                    <Route path="/404" component={NotFoundPage} />
                    <Redirect to="/404" />
                </Switch>
                
            </BrowserRouter>
        )
    }
}
    
export default App;