import React from "react";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="top clearfix">
          <a href="#section8" className="available">
            <i className="ion-ios-email-outline" />
            <span>ליצירת קשר</span>
          </a>
          <div className="right-icons">
            <a className="open-search header-open-search">
              <i className="md md-search" />
            </a>
            <a className="download">
              <i className="md md-file-download" />
            </a>
            <a className="share">
              <i className="md md-more-vert" />
            </a>
          </div>
          <div className="popup">
            <nav className="social-nav">
              <ul className="list-unstyled">
                <li>
                  <a
                    tarfet="_blank"
                    href="https://www.facebook.com/WOWeb.co.il"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="bottom clearfix">
        <a className="responsive-menu-open">
            <i className="ion ion-android-menu" />
          </a>
          <div className="title">
            <a href="https://woweb.co.il/">Woweb</a>
          </div>
          <div className="header-action-button-wrapper">
            <a className="header-action-button action-button">
              <i className="ion ion-information" />
            </a>
          </div>
         
          <nav className="main-nav">
            <ul className="list-unstyled">
              <li className="active">
                <a href="#section1">בית</a>
              </li>
              <li>
                <a href="#section6">העבודות</a>
              </li>
              <li>
                <a href="#section8">יצירת קשר</a>
              </li>
              <li class="sublink">
              <a href="#section1" className="sublink"><Link className="clientstalks" to="clientstalks" spy={true} smooth={true} duration={500} >המלצות</Link></a>
              </li>
              <li id="hot-link">
                <a href="tel:0532456079" className="hot-link" target="_blank">
                  053-2456079 <i className="ion ion-ios-telephone-outline" />
                </a>
              </li>
              <li>
                <a href="https://blog.woweb.co.il" target="_blank">
                  בלוג <i className="ion ion-ios-lightbulb-outline" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
