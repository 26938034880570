import React from "react";
import Form from './Form';

const config = {
  api: 'https://woweb.co.il/api/contact/index.php',
  title: 'צור קשר',
  successMessage: 'פנייתך התקבלה בהצלחה',
  errorMessage: ' תבדקו אם מלאתם את הפרטים נכון ונסו שנית',
  fields:{
    name: '',
    email: '',
    phone: '',
    msg: ''
  },
  fieldsConfig:  [
   { id: 1, placeholder: 'שם מלא', fieldName: 'name', type: 'text', isRequired: true , klassName:'contact-name'},
   { id: 2, placeholder: 'דוא״ל', fieldName: 'email', type: 'email', isRequired: true , klassName:'contact-email'},
   { id: 3, placeholder: 'טלפון', fieldName: 'phone', type: 'phone', isRequired: true , klassName:'contact-phone'},
   { id: 4, placeholder: 'הודעה', fieldName: 'msg', type: 'textarea', isRequired: true , klassName:'message-message'}
  ]
  
}
class SlideOut extends React.Component {
  render() {
    return (
      <div>
        <div className="slide-out-overlay" />
        <div className="slide-out">
          <header className="slide-out-header clearfix">
            <div className="clearfix">
              <a className="slide-out-close pull-left">
                <i className="md md-close" />
              </a>
              <a className="slide-out-share pull-right"> 
                <i className="md md-more-vert" />
              </a>
            </div>
            
            
            <div className="slide-out-popup">
              <nav className="social-nav">
                <ul className="list-unstyled">
                  <li>
                    <a
                      href="https://www.facebook.com/pg/WOWeb.co.il/"
                      target="_blank"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
                
              </nav>
              
            </div>
            
            <div className="image">
              <img
                src="images/yulia.png"
                alt="alt text"
                className="img-responsive"
              />
            </div>
            <div className="content">
            
         <h5>היי, אני יוליה
              <br/>
                יש לי ניסיון מעל 10 שנים כתמכנתת ובונה אתרים.
                <br/>
                ועדיין נשארתי אמפתית לאנשים :)
                <br/>
                אשמח לעזור לך ולתת את המענה המקצועי בWoweb
              </h5>

             
              {/* <span>אפשר למלא את הטופס עם פרטים ואחזור אליך בהקדם</span> */}
            </div>
            
          </header>
          
          <div className="slide-out-widgets">
            <div className="slide-out-widget">
              <h4>ניתן להשאיר כאן את פרטיך ואחזור אליך עם ההצעה הרלוונטית</h4>
              <Form config={config} />
              {/* <form
                action="scripts/contact.php"
                method="post"
                className="form-horizontal contact-form">
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="contact-name"
                      name="contact-name"
                      placeholder="שם מלא"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="email"
                      className="contact-email"
                      name="contact-email"
                      placeholder="דוא״ל"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="phone"
                      className="contact-phone"
                      name="contact-phone"
                      placeholder="טלפון"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <textarea
                      name="contact-message"
                      className="contact-message"
                      rows={3}
                      defaultValue={""}
                      placeholder="הודעה"
                    />
                  </div>
                </div>
                
                <div className="form-group">
                  <div className="col-sm-12 col-sm-offset-3">
                    <button
                      type="submit"
                      className="button solid-button gradient-button"
                    >
                      להשאיר פנייה
                    </button>
                  </div>
                  
                </div>
                
                <div className="contact-loading alert alert-info form-alert">
                  <span className="message">טוען...</span>
                  <button
                    type="button"
                    className="close"
                    data-hide="alert"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
                <div className="contact-success alert alert-success form-alert">
                  <span className="message">נשלח בהצלחה!</span>
                  <button
                    type="button"
                    className="close"
                    data-hide="alert"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
                <div className="contact-error alert alert-danger form-alert">
                  <span className="message">ישגיאה...נסה שנית!</span>
                  <button
                    type="button"
                    className="close"
                    data-hide="alert"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </form> */}
              
            </div>
            
            <div className="slide-out-widget"></div> 
          </div>
          
        </div>
        
      </div>
    );
  }
}

export default SlideOut;
