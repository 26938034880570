import React from 'react';
import './App.css';
import SlideOut from './components/SlideOut';
import Header from './components/Header';
import ResponsiveMenu from './components/ResponsiveMenu';
import SectionHome from './components/SectionHome';
import SectionPortfolio from './components/SectionPortfolio';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import WhatsapButton from './components/WhatsapButton';
import Particles from 'react-particles-js';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'



class HomePage extends React.Component {
    render() {
      return (
    <div className="App">
      <SlideOut/> {/* remove div */}
      <Header/>
      <ResponsiveMenu/>
      <div className="sections" >
        <div className="sections-wrapper clearfix">
        <section id="section404" className="no-padding-bottom active" >
        <div className="section-video">
          <div className="fullscreen-bg">
            <video
              loop
              muted
              autoPlay
              poster="../images/bg.jpg"
              className="fullscreen-bg__video"
            >
              <source src="../video/2.mp4" type="video/mp4" />
              
            </video>
            <div className="overlay-video-screen">
              <h1>
              <span className="red-color">404 error</span>
              <br/>
           אופס, העמוד שחיפשת לא קיים
           <br/> 
          
              </h1>
            
                 
            </div>
          </div>
        </div>
        </section>
        <section id="section1" className="no-padding-bottom">
          <SectionHome/> 
          <div className="cta">
           <div className="cta-sentence">
            <h4>אז איך מתקדמים עם האתר?</h4>
            <p>צפו בתיק העבודות, השאירו לנו פנייה ונחזור אליכם עם הפרטים!</p>
          </div>
          <div className="buttons-cta">
            <a href="#section6" className="button solid-button white sm-link12">
           <Link className="portfolio" to="portfolio" spy={true} smooth={true} duration={500} >תיק עבודות</Link>
            </a>
            <a className="button solid-button main-screen-action-button purple">
              לקבלת הצעת מחיר
            </a>
          </div>
            <Particles className="particles-js"
            params={{
                particles: {
                  number: {
                  value: 300,
                  density: {
                    enable: true,
                    value_area: 2000
                  }
                  },
                  color: {
                  value: "#ff6d5f"
                  },
                  "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#ff6d5f"
                  },
                  polygon: {
                    "nb_sides": 5
                  },
                  "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                  }
                  },
                  "opacity": {
                  "value": 1,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.7,
                    "sync": false
                  }
                  },
                  "size": {
                  "value": 3,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": false
                  }
                  },
                  "line_linked": {
                  "enable": true,
                  "distance": 150,
                  "color": "#f6d365",
                  "opacity": 0.4,
                  "width": 1
                  },
                  "move": {
                  "enable": true,
                  "speed": 6,
                  "direction": "none",
                  "random": false,
                  "straight": false,
                  "out_mode": "out",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                  }
                },
                "interactivity": {
                  "detect_on": "canvas",
                  "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "grab"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "push"
                  },
                  "resize": true
                  },
                  "modes": {
                  "grab": {
                    "distance": 200,
                    "line_linked": {
                    "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                  },
                  "repulse": {
                    "distance": 200,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                  } 
                },
                "retina_detect": true
              
          }} 
            />          
        </div>
      </section>
          {/* <SectionAbout/> */}
          <Element name="portfolio" className="portfolio" ></Element>
          <SectionPortfolio/>
          {/* <SectionMarket/> */}
          <ContactUs/>
        </div>
      </div>
      <Footer/>
      <WhatsapButton/>{/* remove div */}
    </div>
      );
    }
  }
  
  export default HomePage;
