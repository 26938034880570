import React from "react";
import Form from './Form1';

const config = {
  api: 'https://woweb.co.il/api/contact/index.php',
  title: 'צור קשר',
  successMessage: 'פנייתך התקבלה בהצלחה',
  errorMessage: ' תבדקו אם מלאתם את הפרטים נכון ונסו שנית',
  fields:{
    name: '',
    email: '',
    phone: '',
    msg: ''
  },
  fieldsConfig:  [
   { id: 1, placeholder: 'שם מלא', fieldName: 'name', type: 'text', isRequired: true , klassName:'contact-name'},
   { id: 2, placeholder: 'דוא״ל', fieldName: 'email', type: 'email', isRequired: true , klassName:'contact-email'},
   { id: 3, placeholder: 'טלפון', fieldName: 'phone', type: 'phone', isRequired: true , klassName:'contact-phone'},
   { id: 4, placeholder: 'הודעה', fieldName: 'msg', type: 'textarea', isRequired: true , klassName:'message-message'}
  ]
  
}
class ContactUs extends React.Component {
  render() {
    return (
      <section id="section8">
        <div className="container contact-main">
          <h3>אנחנו זמינים לכל שאלה והתייעצות</h3>
          <p>
            בטלפון <a href="tel:053-2456079">053-2456079</a> ניתן גם להשאיר
            פרטים ונחזור אליך:
          </p>
          <div className="row">
            <div className="col-md-6 col-md-offset-2">
            <Form config={config} />
              {/* <form
                action="scripts/contact.php"
                method="post"
                className="form-horizontal contact-form form-block"
              >
                <div className="contacts-block">
                  <div className="form-group form-group-phone col-sm-4">
                    <div className="phone">
                      <input
                        type="phone"
                        className="contact-phone"
                        name="contact-phone"
                        placeholder="טלפון"
                      />
                    </div>
                    
                  </div>
                  
                  <div className="form-group form-group-email col-sm-4">
                    <div className="email">
                      <input
                        type="email"
                        className="contact-email"
                        name="contact-email"
                        placeholder="דוא״ל"
                      />
                    </div>
                    
                  </div>
                  
                  <div className="form-group form-group-name col-sm-4">
                    <div className="name">
                      <input
                        type="text"
                        className="contact-name"
                        name="contact-name"
                        placeholder="שם מלא"
                      />
                    </div>
                    
                  </div>
                  
                </div>
                <div className="form-group form-group-message">
                  <div className="message">
                    <textarea
                      name="contact-message"
                      className="contact-message"
                      placeholder="הודעה"
                      rows={3}
                      defaultValue={""}
                    />
                  </div>
                  
                </div>
                
                <div className="form-group">
                  <button
                    type="submit"
                    className="button solid-button gradient-button"
                  >
                    שלח
                  </button>
                </div>
                
                <div className="contact-loading alert alert-info form-alert">
                  <span className="message">שולח</span>
                  <button
                    type="button"
                    className="close"
                    data-hide="alert"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
                <div className="contact-success alert alert-success form-alert">
                  <span className="message">הפניי נשלחה בהצלחה!</span>
                  <button
                    type="button"
                    className="close"
                    data-hide="alert"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
                <div className="contact-error alert alert-danger form-alert">
                  <span className="message">אופס! נסה שוב!</span>
                  <button
                    type="button"
                    className="close"
                    data-hide="alert"
                    aria-label="Close"
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </form> */}
              
            </div>
            <div className="col-md-4">
              <ul className="list-icons list-unstyled">
                <li>
                  <i className="ion-iphone" />
                  נייד: 053-2456079
                </li>
                <li>
                  <i className="ion-ios-email-outline" />
                  אימייל:
                  <a href="mailto:yuladevin@gmail.com">yuladevin@gmail.com</a>
                </li>
              </ul>
              <div className="spacer" />
              <div className="social-icons">
                <a className="social-icon">
                <i class="ion ion-logo-facebook">f</i>
                </a>
                
              </div>
              
              <div className="spacer" />
            </div>
          </div>
          <div className="map" id="map" />
        </div>
        
      </section>
    );
  }
}

export default ContactUs;
