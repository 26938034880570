import React from 'react';
import '../App.css';
import Footer from '../components/Footer';
import WhatsapButton from '../components/WhatsapButton';
import Particles from 'react-particles-js'; 

class HomePage extends React.Component {
    render() {
      return (
    <div className="App">
      <div className="sections" >
        <div className="sections-wrapper clearfix">
        <section id="sectionthankyou" className="no-padding-bottom active" >
        <div className="section-video">
          <div className="fullscreen-bg">
            <video
              loop
              muted
              autoPlay
              poster="../images/bg.jpg"
              className="fullscreen-bg__video"
            >
              
            </video>
            <div className="overlay-video-screen">
              <h1>
              <span className="red-color"> הפנייה התקבלה בהצלחה</span>
              </h1>
              <br/>
              <p>
              נחזור אליך בהקדם, המשך יום טוב!
                </p>
           <br/>                  
            </div>
          </div>
        </div>
        </section>
        </div>
      <Footer/>
      <WhatsapButton/>{/* remove div */}
    </div>
    </div>
      );
    } 
  }
  
  export default HomePage;
