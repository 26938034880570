import React from "react";

class ResponsiveMenu extends React.Component {
  render() {
    return (
      <div className="responsive-menu">
        <a className="responsive-menu-close">
          סגור <i className="ion-android-close" />
        </a>
        <nav className="responsive-nav" /> {}
      </div>
    );
  }
}

export default ResponsiveMenu;
