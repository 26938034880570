import React from "react";

class WhatsapButton extends React.Component {
  render() {
    return (
      <div>
        <a
          href="https://api.whatsapp.com/send?phone=+972532456079&text=שלום, אני אשמח לקבל הצעה עבור"
          className="float"
          target="_blank"
        >
          <i className="ion-social-whatsapp-outline my-float" />
        </a>
      </div>
    );
  }
}

export default WhatsapButton;
