import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "./styles.css";
import Loading from '../../images/loader.gif'
import {
	withRouter
} from 'react-router-dom'; 


/**
 * @component Form
 * @props - { object } -  config
 */
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSent: false,
      error: null
    };
  }
  /**
  * @function handleFormSubmit
  * @param e { obj } - form event 
  * @return void
  */
  state = {
    loading : false
  }
  fetchData = () =>{
    this.setState({loading:true});
  }
  handleFormSubmit = e => {
    e.preventDefault();
    this.fetchData();
    axios({
      method: "post",
      url: 'https://woweb.co.il/api/contact/index.php',
      headers: { "content-type": "application/json" },
      data: this.state
    })
      .then(result => {
        this.setState({loading:false});
        if (result.data.sent) {
          this.setState({
            mailSent: result.data.sent
          });
          //console.log('result.data.sent: '+result.data.sent);
          this.props.history.push('/thankyou');
          this.setState({ error: false });
        } else {
          this.setState({ error: true });
        }
      })
      //.catch(error => this.setState({ error: error.message }));
  };
  /**
    * @function handleChange
    * @param e { obj } - change event
    * @param field { string } - namve of the field
    * @return void
    */
  handleChange = (e, field) => {
    let value = e.target.value;
    let updateValue = {};
    updateValue[field] = value;
    this.setState(updateValue);
  }
  
  
  render() {
    const { title, successMessage, errorMessage, fieldsConfig } = this.props.config;
    const {loading} = this.state;
    return (
      <div className="App">
        <div>
          <form action="#" className="form-horizontal contact-form form-block">
            <div className="contacts-block">
            {fieldsConfig &&
              fieldsConfig.map(field => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== "textarea" ? (
                      <div className="form-group form-group-phone col-sm-4">
                        <div className={field.fieldName}>
                      <React.Fragment>
                        <input
                          type={field.type}
                          className={field.klassName}
                          placeholder={field.placeholder}
                          value={field.name}
                          onChange={e => this.handleChange(e, field.fieldName)}
                        />
                      </React.Fragment>
                      </div>
                      </div>
                    ) : (
                      <div className="form-group form-group-message">
                      <React.Fragment>
                      <div className={field.fieldName}>
                        <textarea className={field.klassName} placeholder={field.placeholder} onChange={e => this.handleChange(e, field.fieldName)} value={field.name} />
                      </div>
                      </React.Fragment>
                      </div>
                    )}
                  </React.Fragment>
                  
                );
              })}
            <button type="submit" onClick={e => this.handleFormSubmit(e)} disabled={loading} className="button solid-button gradient-button">
              {loading && <img className="send-loading" src={Loading} width="30px" />} 
              להשאיר פנייה</button>
            <div>
              {this.state.mailSent && <div className="sucsess">{successMessage}</div>}
              {this.state.error && <div className="error">{errorMessage}</div>}
            </div>
            </div>
          </form>
          </div>
      </div>
    );
  }
}

export default withRouter(Form);
//propTypes for the component
Form.propTypes = {
  config: PropTypes.object.isRequired
};
