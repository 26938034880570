import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="top">
          <div className="container">
            <div className="row">
              <div className="col-sm-3 list-menu">
                <h4>מאמרי ערך</h4>
                <p>
                  <a href="https://blog.woweb.co.il/%D7%90%D7%91%D7%98%D7%97%D7%AA-%D7%94%D7%90%D7%AA%D7%A8/">
                    אבטחת האתר
                  </a>
                </p>
                <p>
                  <a href="https://blog.woweb.co.il/wordpress-%D7%A6%D7%A2%D7%93-%D7%90%D7%97%D7%93-%D7%A7%D7%93%D7%99%D7%9E%D7%94-%D7%91%D7%9E%D7%A0%D7%95%D7%A2%D7%99-%D7%94%D7%97%D7%99%D7%A4%D7%95%D7%A9/">
                    קידום SEO עם WordPress
                  </a>
                </p>
                <p>
                  <a href="https://blog.woweb.co.il/set-design-for-creatives/">
                    חשיבות איפיון Ui/Ux
                  </a>
                </p>
                <p>
                  <a href="https://blog.woweb.co.il/%D7%9E%D7%94-%D7%94%D7%94%D7%91%D7%93%D7%9C-%D7%91%D7%99%D7%9F-%D7%90%D7%AA%D7%A8-%D7%9E%D7%95%D7%AA%D7%90%D7%9D-%D7%9C%D7%9E%D7%9B%D7%A9%D7%99%D7%A8%D7%99%D7%9D-%D7%9C%D7%91%D7%99%D7%9F-%D7%90%D7%AA/">
                    מה ההבדל בין אתר מותאם למכשירים לבין אתר רספונסיבי?
                  </a>
                </p>
                <p>
                  <a href="https://blog.woweb.co.il/web-accessibility-%D7%90%D7%AA%D7%A8-%D7%A0%D7%92%D7%99%D7%A9-%D7%9C%D7%9B%D7%9C-%D7%90%D7%99%D7%A9/">
                    אתר נגיש
                  </a>
                </p>
              </div>
              
              <div className="col-sm-3 consultation">
                <h4>
                  לשיחת ייעוץ חינם השאירו לנו פרטים,
                  <br />
                  ונחזור אליכם בהקדם
                </h4>
                <a
                  className="button solid-button main-screen-action-button purple"
                  style={{
                    float: "right"
                  }}
                >
                  להשארת פרטים
                </a>
                <div
                  className="social-icons"
                  style={{
                    display: "none"
                  }}
                >
                  <form
                    action="scripts/contact.php"
                    method="post"
                    className="form-horizontal form-footer"
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        className="contact-name"
                        name="contact-name"
                        placeholder="שם מלא"
                      />
                    </div>
                    
                    <div className="form-group">
                      <input
                        type="text"
                        className="contact-email"
                        name="contact-email"
                        placeholder="דוא״ל"
                      />
                    </div>
                    
                    <div className="form-group">
                      <input
                        type="tel"
                        className="contact-phone"
                        name="contact-phone"
                        placeholder="טלפון"
                      />
                    </div>
                    
                    <div className="form-group">
                      <textarea
                        name="contact-message"
                        className="contact-message"
                        rows={3}
                        placeholder="הודעה"
                        defaultValue={""}
                      />
                    </div>
                    
                    <div className="form-group form-group-button">
                      <button
                        type="submit"
                        className="button solid-button gradient-button"
                      >
                        להשאיר פנייה
                      </button>
                    </div>
                    
                    <div className="contact-loading alert alert-info form-alert in">
                      <span className="message">טוען...</span>
                      <button
                        type="button"
                        className="close"
                        data-hide="alert"
                        aria-label="Close"
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                    <div className="contact-success alert alert-success form-alert in">
                      <span className="message">נשלח בהצלחה!</span>
                      <button
                        type="button"
                        className="close"
                        data-hide="alert"
                        aria-label="Close"
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                    <div className="contact-error alert alert-danger form-alert in">
                      <span className="message">ישגיאה...נסה שנית!</span>
                      <button
                        type="button"
                        className="close"
                        data-hide="alert"
                        aria-label="Close"
                      >
                        <i className="fa fa-times" />
                      </button>
                    </div>
                  </form>
                </div>
                
              </div>
              
              <div className="col-sm-3 personal-information">
                <h4>לכל שאלה אנחנו כאן</h4>
                <ul className="list-icons list-unstyled">
                  <li>
                    <i className="ion-iphone" />
                     נייד: 
                    <a href="tel:0532456079"> 053-2456079</a>
                  </li>
                  <li>
                    <i className="ion-ios-email-outline" />
                    אימייל:
                    <a href="mailto:yuladevin@gmail.com"> yuladevin@gmail.com</a>
                  </li>
                  <li>
                  עקוב אחרינו בפייסבוק
                    <a
                      href="https://www.facebook.com/WOWeb.co.il/"
                      className="social-icon"
                    >
                      <i className="ion ion-logo-facebook" >f</i>
                      
                    </a>
                  </li>
                </ul>
              </div>
              
              <div className="col-sm-3 footer-about">
                <img
                  src="images/logo-white.png"
                  className="footer-logo"
                  alt="woweb"
                />
                <h4>פתרונות טכנולוגיים</h4>
                <p>
                 לעסקים וארגונים<br />
                </p>
              </div>
              
            </div>
            
          </div>
          
        </div>
        
        <div className="bottom">Copyright © כל הזכויות שמורות לואווב</div> 
      </footer>
    );
  }
}

export default Footer;
