import React from "react";
import ReactTypingEffect from 'react-typing-effect';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Form from './Form';

const config = {
  api: 'https://woweb.co.il/api/contact/index.php',
  title: 'צור קשר',
  successMessage: 'פנייתך התקבלה בהצלחה',
  errorMessage: 'בבקשה תבדקו אם מלאתם את הפרטים נכון ונסו שנית',
  fields:{
    name: '',
    email: '',
    phone: '',
    msg: ''
  },
  fieldsConfig:  [
   { id: 1, placeholder: 'שם מלא', fieldName: 'name', type: 'text', isRequired: true , klassName:'contact-name'},
   { id: 2, placeholder: 'דוא״ל', fieldName: 'email', type: 'email', isRequired: true , klassName:'contact-email'},
   { id: 3, placeholder: 'טלפון', fieldName: 'phone', type: 'phone', isRequired: true , klassName:'contact-phone'},
   { id: 4, placeholder: 'הודעה', fieldName: 'msg', type: 'textarea', isRequired: true , klassName:'message-message'}
  ]
  
}
class SectionHome extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
   
  }


  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  
  render() {
    
    return (
      <div>
       
        <a onClick={() => scroll.scrollToTop()} id="return-to-top"><i className="ion ion-ios-arrow-thin-up"></i></a> 
        <div className="section-video">
          <div className="fullscreen-bg">
            <video
              loop
              muted
              autoPlay
              poster="../images/bg.jpg"
              className="fullscreen-bg__video"
            >
              <source src="../video/2.mp4" type="video/mp4" />
              
            </video>
            <div className="overlay-video-screen">
              <h1>
            פתרונות טכנולוגיים
                 <span> </span><span className="underline">מתקדמים</span>
              </h1>
              <h1>לעסקים וארגונים</h1>
              
              {/* <p className="button-row">
                
                
                <a className="button solid-button main-screen-action-button purple">
                  לקבלת הצעת מחיר
                </a>
              </p> */}
              {/*<span className="cta-sent"><ReactTypingEffect
      text="לקבלת שיחת ייעוץ חינם מלא פרטים" speed="100" //text=["Hello.", "World!"]
            /></span> */}
             
             {/* <Form config={config} /> */}
              {/* <form
                action="#"
                className="form-horizontal contact-form">
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="contact-name"
                      name="name"
                      id="name"
                      placeholder="שם מלא"
                      value={this.state.name}
    onChange={e => this.setState({ name: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="email"
                      className="contact-email"
                      name="email"
                      id="email"
                      placeholder="דוא״ל"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12">
                    <input
                      type="phone"
                      className="contact-phone"
                      name="phone"
                      id="phone"
                      placeholder="טלפון"
                      value={this.state.phone}
    onChange={e => this.setState({ phone: e.target.value })}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-12 col-sm-offset-3">
                    <button
                      type="submit"
                      className="button solid-button gradient-button"
                      onClick={e => this.handleFormSubmit(e)}
                    >
                    להשאיר פנייה
                    </button>
                    
                  </div>
                 
                </div>
                
                <div className="contact-loading alert alert-info form-alert">
                  <span className="message">טוען...</span>
                 </div>

                 <div>
                  {this.state.mailSent &&
                    <div>פניתך נשלחה בהצלחה</div>
                  }
                </div>
              </form>           
               */}
              <Link className="more" to="more" spy={true} smooth={true} duration={500} ><a className="arrow-down" /></Link>
            </div>
          </div>
        </div>
        <Element name="more" className="more" >
         
        </Element>
        <div className="container winner">
          <h3>+ 230 לקוחות מרוצים</h3>
          <p>מזמנים אותכם להצטרף למאגר לקוחותינו וגם ליהנות משירות מעולה</p>
        </div>
        <div className="container we-clients">
          <div className="row">
            <div className="owl-carousel-1">
              <div className="client-logo">
                <img src="../images/clients/resized_files/eagleray.jpg" alt="logo eagleray" />
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/ginger-gene1.jpg" alt="logo ginger-gene" />
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/ikan-maas.jpg" alt="logo ikan-maas" />
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/kivunim.jpg" alt="logo kivunim" />
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/lightico.jpg" alt="logo lightico" />
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/maytronics.jpg" alt="logo maytronics" />
              </div>
              <div className="client-logo">
                <img
                  src="../images/clients/resized_files/merkaz-hizdamnut.jpg"
                  alt="logo merkaz hizdamnut"
                />
              </div>
              <div className="client-logo">
                <img
                  src="../images/clients/resized_files/misrad-habitahon.jpg"
                  alt="logo misrad habitahon"
                />
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/alex-bonner.jpg" alt="logo bonner"/>
              </div>
              
              <div className="client-logo">
                <img src="../images/clients/resized_files/bright-biz.jpg" alt="logo brightbiz"/>
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/israzon.jpg" alt="logo israzon"/>
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/walkme.jpg" alt="logo walkme"/>
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/misrad-klita.jpg" alt="logo misrad klita"/>
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/anewspirit.jpg" alt="logo anewspirit"/>
              </div>
              <div className="client-logo">
                <img src="../images/clients/resized_files/combo-music.jpg" alt="logo combomusic"/>
              </div>
            </div>
          </div>
        </div>
        <div className="we-services">
          <div className="services" id="we-services">
            <h3>שירותינו</h3>
            <p>מה אנחנו יודעים לעשות הכי טוב?</p>
            <div className="column column-1">
              {/* <div className="service">
                <div className="icon">
                  <i className="ion ion-ios-flask-outline" />
                </div>
                <h5>
                  <a className="form-action">אפיון ופסיכולוגיה שיווקית</a>
                </h5>
                <p>
                  אפיון אתרים הוא הצעד הראשון בדרכך לאתר מצליח! תהליכי אפיון אתר
                  מורכבים ומקצועיים הם חלק בלתי נפרד מתהליך פיתוח מנצח ואיכותי
                  שיכול לחסוך תקציב משמעותי
                </p>
              </div> */}
              <div className="service service-1">
                <div className="icon">
                  <i className="ion ion-ios-gear-outline" />
                </div>
                <h5>
                  <a className="form-action">Integrations</a>
                </h5>
                <p>יש לנו ניסיון רב באינטגרציות למערכות ליבה בארגונים וחברות</p>
              </div>
              
              
              <div className="service active">
                <div className="icon">
                  <i className="ion ion-social-github-outline" />
                </div>
                <h5>
                  <a className="form-action">תכנות ופיתוח</a>
                </h5>
                <p
                  style={{
                    color: "#fff!important"
                  }}
                >
                  מעל 230 פרויקטים ועשור של ניסיון הפכו אותנו למומחים במה שאנחנו
                  עושים
                </p>
              </div>
              <div className="service">
                <div className="icon">
                  <i className="ion ion-ios-people-outline" />
                </div>
                <h5>
                  <a className="form-action">ניהול פרוייקטים</a>
                </h5>
                <p>
                  אנחנו ננהל את הפרויקט שלכם שלב אחרי שלב כך שתגיעו ליעדים שלכם
                  עם תחושת ההצלחה
                </p>
              </div>
              
              {/* <div className="service service-3">
                <div className="icon">
                  <i className="ion ion-ios-flower-outline" />
                </div>
                <h5>
                  <a className="form-action">עיצוב וקריאטיב</a>
                </h5>
                <p>
                  אנחנו נוביל אתכם בתהליך עיצוב ויצירת חווית משתמש, שמטרתו
                  להנגיש את האתר בצורה אופטימלית ולגרום לחווית משתמש מעולה
                </p>
              </div> */}
              
            </div>
            {/* <div className="column column-2"> */}
              
              {/* <div className="service">
                <div className="icon">
                  <i className="ion ion-ios-filing-outline" />
                </div>
                <h5>
                  <a className="form-action">שרתים, דומיינים ודוא"ל</a>
                </h5>
                <p>נספק לך את כל הכלים המתאימים ביותר להשקת הפרויקט </p>
              </div> */}
              
              
              
            {/* </div> */}
          </div>
          
        </div>
        <Element name="clientstalks" className="clientstalks" ></Element>
        <div className="clients-talks">
          <div className="experience-block clearfix">
            <div className="meta">
              <span className="year">לקוחותינו מספרים</span>
              <div className="testimonial-slider owl-carousel">
                <div className="client-tells">
                  <div className="image">
                    <img src="../images/customers/amirha.jpg" alt="image Amir Haaronovich" />
                  </div>
                  <div className="sep" />
                  <p>
                    "ב WOWeb עובדים בצורה מקצועית ביותר, כל בקשה שהייתה לי בוצעה
                    באופן מיידי על הצד הטוב ביותר. במיוחד בזכות יוליה יש לי אתר
                    ברמה גבוהה מאוד"
                  </p>
                  <span className="author">
                    מייסד חברה נטיב-קראטיב , אמיר אהרונוביץ
                  </span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img
                      src="../images/customers/shahar-shinar.png"
                      alt="image Shachar Shin'ar"
                    />
                  </div>
                  <div className="sep" />
                  <p>
                    "הגעתי ליוליה אחרי שעברתי מספר אנשי מקצוע, שלא הצליחו לקלוע
                    למה שחיפשתי, וכבר הייתי מיואשת מלהוציא כספים ולקבל תמורה
                    שאני מתפשרת עליה. אחרי שתי פגישות עם יוליה בהן היא שאלה אותי
                    שאלות מכוונות, ובעדינות ובהקשבה נתנה לי טיפים ועצות נהדרות,
                    קיבלתי סקיצה שהתאהבתי בה מהרגע הראשון! קיבלתי יחס מקצועי,
                    אמין, כנה, קשוב... עם הרבה לב ואכפתיות. יוליה נתנה לי הרגשה
                    אישית וחמה, הרגשה של שותפות!"
                  </p>
                  <span className="author">מטפלת הוליסטית , שחר שנער</span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img
                      src="../images/customers/rafael-landuo.jpg"
                      alt="image Rafael Landuo"
                    />
                  </div>
                  <div className="sep" />
                  <p>
                    "אנחנו מאוד מרוצים מהעבודה עם יוליה. הזמינות שלה והמאמץ
                    לעמוד בדדליינים היה מעולה. <br />
                    נשמח לשתף פעולה גם בעתיד."
                  </p>
                  <span className="author">
                    מייסד חברת איגליריי , רפאל לנדאו
                  </span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img src="../images/customers/roni-marom.jpg" alt="image Roni Marom" />
                  </div>
                  <div className="sep" />
                  <p>
                    יוליה היקרה נתנה לנו שירות אישי ואכפתי מא' ועד ת' עבודת
                    העיצוב הייתה מדהימה... ואנחנו מאושרים מהתוצאה היפיפייה!
                    <br />
                    הצוות אדיב מאוד, ואפשר להרגיש בנוח לפנות לכל שאלה, בקשה או
                    עזרה טכנית ותמיד לקבל מענה ברוחב ומקצועיות.
                    <br />
                    שמחים מאוד שבחרנו לעבוד אתכם WOWeb תודה רבה!
                  </p>
                  <span className="author">דרך הידיים , רוני מרום</span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img
                      src="../images/customers/alex-bonner.jpg"
                      alt="image Alex Bonner"
                    />
                  </div>
                  <div className="sep" />
                  <p>
                    יוליה אלופה! יצירתית ומבריקה, אדם עם לב זהב שכיף לעבוד איתה!
                  </p>
                  <span className="author">מרצה ומלווה עסקי , אלכס בונר</span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img
                      src="../images/customers/dotan-horoviz.jpg"
                      alt="image Dotan Horoviz"
                    />
                  </div>
                  <div className="sep" />
                  <p>
                    מומלץ בחום רב מאוד!
                    <br />
                    יוליה מקצועית, מדויקת וזריזה - ומבינה עניין.
                  </p>
                  <span className="author">
                    מייסד חברת brightbiiz ,דותן הורביץ
                  </span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img
                      src="../images/customers/ephratt-apel.jpg"
                      alt="image Ephratt Apel"
                    />
                  </div>
                  <div className="sep" />
                  <p>
                    עבדתי עם וואווב על פרויקט אישי והופתעתי מהיחס החם, הדיוק
                    והאכפתיות. נתנו לי שירות מדהים, עד הפרטים הקטנים. <br />
                    לא עצרנו עד שהכל היה פיקס. איכות כזו לא מוצאים בכל מקום.
                    תודה רבה ואווב!!!
                  </p>
                  <span className="author">מעצבת , אפרת אפל</span>
                </div>
                <div className="client-tells">
                  <div className="image">
                    <img
                      src="../images/customers/ella-shahaf.jpg"
                      alt="image Ella Shahaf"
                    />
                  </div>
                  <div className="sep" />
                  <p>
                    העבודה עם יוליה על האתר הייתה מדויקת מאוד בשביל מה שחיפשתי.
                    בפגישה הראשונה שלנו היא הקשיבה טוב לצרכים ולנקודת המבט שלי
                    וכבר תוך יומיים שלחה לי סקיצה מהממת של עיצוב האתר והלוגו.
                    התחלנו לעבוד על הניואנסים ולאורך כל הדרך יוליה באה לקראתי,
                    שינתה איפה ששכנעתי אותה כשזה לא היה נראה לי, אבל גם ידעה
                    להסביר את הסיבות לאופן בניית האתר מתוך ההבנה המקצועית שלה את
                    חוויית המשתמש. יוליה כל הזמן אוספת עוד ועוד רעיונות חדשניים
                    שיש בשוק, כדי להישאר מעודכנת ועושה את הדברים ברמת דיוק
                    מרשימה. ממליצה ממש ממש ובחום!
                  </p>
                  <span className="author">נתיב חדש , אלה שחף</span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="team">
          <h3>הצוות המקצועי שמאחורי המסך</h3>
          <p>הכירו אותנו</p>
          <div className="col-sm-12 workers">
            <ul className="ch-grid">
              <li>
                <div className="ch-item ch-img-2">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-2" />
                      <div className="ch-info-back">
                        <h3>
                          יוליה דווין
                          <br />
                          Manager & Technical Architect
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="ch-item ch-img-3">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-3" />
                      <div className="ch-info-back">
                        <h3>
                          יבגני דווין
                          <br />
                          Front-end Programmer
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              <li>
                <div className="ch-item ch-img-1">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-1" />
                      <div className="ch-info-back">
                        <h3>
                          פבל אוסטינוב
                          <br />
                          Back-end Programmer
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="ch-item ch-img-1">
                  <div className="ch-info-wrap">
                    <div className="ch-info">
                      <div className="ch-info-front ch-img-4" />
                      <div className="ch-info-back">
                        <h3>
                          ילנה פלינר
                          <br />
                          UX/UI Designer
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>   
        </div>  
    );
  }
}

export default SectionHome;
